import { Box, ThemeProvider, createTheme } from '@material-ui/core';
import LumoaLogo from '../../public/lumoa.png';

const StaticPage = () => {
  return (
    <ThemeProvider theme={createTheme()}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <img width="30%" alt="Lumoa" src={LumoaLogo} />
      </Box>
    </ThemeProvider>
  );
};

export default StaticPage;
