import { merge } from 'lodash';
import { createTheme } from '@material-ui/core/styles';

const getTheme = (config) => {
  const baseTheme = {
    typography: {
      fontFamily: `'Montserrat', sans-serif;`,
      h6: {
        fontWeight: 'bold',
        lineHeight: '24px',
        letterSpacing: '0.25px',
      },
      subtitle2: {
        fontSize: '1rem',
      },
    },
    palette: {
      primary: {
        main: '#27778B',
      },
      form: '#ffffff',
      score_border: '#ccdbeb',
      borderColor: 'rgba(0,0,0,0.32)',
      text: {
        primary: '#143036',
      },
      button: {
        primary: '#27778B',
      },
      buttonFont: {
        primary: '#ffffff',
      },
      background: {
        default: '#ffffff',
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          '& $notchedOutline': {
            borderColor: 'rgba(0,0,0,0.32)',
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          fontSize: '14px',
        },
      },
      MuiButton: {
        label: {
          textTransform: 'none',
        },
      },
    },
  };

  if (config && config.survey && config.survey.style) {
    const {
      survey: { style },
    } = config;
    const companyTheme = {
      // typography: {
      //   fontFamily: style.fontFamily,
      // },
      palette: {
        primary: {
          main: style.primaryColor,
        },
        form: style.formColor,
        score_border: style.scoreBorderColor,
        text: {
          primary: style.primaryColor,
        },
        button: {
          primary: style.buttonColor,
        },
        buttonFont: {
          primary: style.buttonFontColor,
        },
        background: {
          default: style.background,
        },
        detractor: style.scoreNegativeColor,
        promoter: style.scorePositiveColor,
        passive: style.scoreNeutralColor,
      },
    };
    return createTheme(merge(baseTheme, companyTheme));
  }
  return createTheme(baseTheme);
};

export default getTheme;
