import { FormGroup, Typography, TextField } from '@material-ui/core';

const Select = ({ field, onChange, values, errors }) => {
  const { translations } = field;
  const isError = errors[field.key];
  const fieldId = `${field.key}-select`;
  return (
    <FormGroup>
      <Typography
        component="label"
        variant="subtitle2"
        color="textPrimary"
        aria-label={translations.text}
        htmlFor={fieldId}
      >
        {translations.text}
        {field.settings?.required === true && ' *'}
      </Typography>
      <TextField
        SelectProps={{
          native: true,
          displayEmpty: true,
          id: fieldId,
          'data-testid': 'select',
        }}
        select
        value={values[field.key]}
        onChange={(value) => {
          onChange(field.key, value.target.value);
        }}
        className="form-control"
        variant="outlined"
        required={Boolean(field.settings?.required)}
        error={isError}
        helperText={
          isError ? field.translations.required || 'Field is required' : ''
        }
      >
        <option value="" aria-label="No Selection">
          {field.translations.placeholder}
        </option>
        {field.options
          .filter(
            (o) =>
              field.translations.options[o] ||
              field.translations.options[o.replace(`${field.key}.`, '')],
          )
          .map((o) => {
            return (
              <option key={o} value={o}>
                {field.translations.options[o] ||
                  field.translations.options[o.replace(`${field.key}.`, '')]}
              </option>
            );
          })}
      </TextField>
    </FormGroup>
  );
};

export default Select;
