import axios from 'axios';
import { feedbackAPI, apiVersion, surveyAPI } from '../config';

export function configFetch(path, query = {}) {
  return axios.get(`${surveyAPI}/api/${apiVersion}/surveys/public/${path}`, {
    params: query,
  });
}

export function lumoaFeedbackSend(path, data, { campaign }) {
  return axios.post(`${feedbackAPI}/api/${apiVersion}/${path}`, data, {
    headers: {
      'X-Lumoa-Campaign': campaign,
    },
  });
}
