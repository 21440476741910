import { FormGroup, Typography, TextField } from '@material-ui/core';

const Text = ({ field, onChange, values, errors }) => {
  const { translations } = field;
  const isError = errors[field.key];
  const fieldId = `${field.key}-text`;
  const fieldRequired = Boolean(field.settings?.required);

  return (
    <FormGroup>
      {translations.text && (
        <Typography
          component="label"
          variant="subtitle2"
          color="textPrimary"
          aria-label={translations.text}
          htmlFor={fieldId}
        >
          {translations.text}
          {field.settings?.required === true && ' *'}
        </Typography>
      )}
      <TextField
        error={isError}
        variant="outlined"
        required={fieldRequired}
        multiline
        minRows={5}
        maxRows={8}
        id={fieldId}
        defaultValue={values[field.key]}
        onChange={(e) => onChange(field.key, e.target.value)}
        aria-label="Please, type your feedback"
        maxLength={5000}
        helperText={
          isError ? field.translations.required || 'Field is required' : ''
        }
      />
    </FormGroup>
  );
};

export default Text;
