import {
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@material-ui/core';

const ContactRequest = ({ field, onChange, values }) => {
  const { translations } = field;
  const fieldId = `contact-request`;
  return (
    <FormGroup aria-atomic="true">
      <FormControlLabel
        control={
          <Checkbox
            onChange={() => onChange('contactRequest', !values.contactRequest)}
            name={translations.text}
            color="primary"
            id={fieldId}
          />
        }
        label={translations.text}
      />
      {values.contactRequest && (
        <TextField
          inputProps={{
            'aria-label': translations.text,
            'aria-live': 'polite',
            'aria-relevant': 'additions removals',
          }}
          data-testid="contactDetails"
          name="contactDetails"
          fullWidth
          type="text"
          variant="outlined"
          placeholder={translations.email_placeholder}
          onChange={(e) => onChange('contactEmail', e.target.value)}
          maxLength={100}
        />
      )}
    </FormGroup>
  );
};

export default ContactRequest;
