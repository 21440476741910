import { useContext } from 'react';
import {
  Box,
  MenuItem,
  Select,
  ListItemIcon,
  ListItemText,
  FormControl,
} from '@material-ui/core';
import { capitalize } from 'lodash';
import { hasFlag } from 'country-flag-icons';
import * as CountryFlags from 'country-flag-icons/react/3x2';
import { LanguageContext } from './LanguageProvider';

const LanguageSelector = () => {
  const { lang, setLang, localizedLanguages } = useContext(LanguageContext);

  const handleChangelanguage = (event) => {
    const {
      target: { value },
    } = event;
    setLang(value);
  };

  if (!localizedLanguages || localizedLanguages.length <= 1) {
    return null;
  }

  return (
    <FormControl style={{ margin: 5, minWidth: 80, width: '50%' }}>
      <Select
        value={lang}
        autoWidth
        SelectDisplayProps={{
          style: { display: 'flex', alignItems: 'center' },
        }}
        onChange={handleChangelanguage}
      >
        {localizedLanguages.map(({ label, value, countryCode }) => {
          const flagLabel = countryCode.toUpperCase();

          const Flag = hasFlag(flagLabel) ? CountryFlags[flagLabel] : Box;

          return (
            <MenuItem value={label} key={label} style={{ height: 45 }}>
              <ListItemIcon style={{ height: 24 }}>
                <Flag />
              </ListItemIcon>
              <ListItemText>{capitalize(value)}</ListItemText>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
