import { Button, Typography, Box, makeStyles } from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import { darken } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import SharingButtons from './SharingButtons';
import { getScoreField, getScoreLevel } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  items: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  title: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  success: {
    color: theme.palette.success.main,
  },
  successButton: {
    color: theme.palette.buttonFont.primary,
    backgroundColor: theme.palette.button.primary,
    '&:hover': {
      backgroundColor: darken(theme.palette.button.primary, 0.2),
    },
  },
}));

const Success = ({ translations, values, config, lang }) => {
  const classes = useStyles();
  const scoreField = getScoreField(config);
  const { score } = values;
  const scoreLevel = getScoreLevel(score, scoreField);
  const transObj = translations.thank_you[scoreLevel];
  const hasSomeButtons =
    config.survey.settings.socialMedia &&
    config.survey.settings.socialMedia[lang];
  const { answeredInfo } = config;

  return (
    <Box p={2} display="flex" flexDirection="column">
      {answeredInfo ? (
        <Box display="flex" flexDirection="column" alignItems="center" p={4}>
          <ThumbUp className={classes.success} style={{ fontSize: 60 }} />
          <Typography
            className={classes.title}
            variant="h4"
            color="primary"
            aria-label={answeredInfo.text}
          >
            {answeredInfo.text}
          </Typography>
        </Box>
      ) : (
        <>
          <Typography
            className={classes.title}
            align="center"
            variant="h5"
            aria-label={transObj.title}
          >
            {transObj.title}
          </Typography>
          <Typography
            className={classes.items}
            align="center"
            variant="body1"
            aria-label={transObj.text}
          >
            {transObj.text}
          </Typography>
        </>
      )}

      {hasSomeButtons && scoreLevel === 'promoter' && (
        <SharingButtons
          className={classes.items}
          title={translations.social_media.title}
          facebook={translations.social_media.facebook}
          twitter={translations.social_media.twitter}
          googlePlus={translations.social_media.googlePlus}
          linkedin={translations.social_media.linkedin}
          instagram={translations.social_media.instagram}
          youtube={translations.social_media.youtube}
        />
      )}
      {!isEmpty(transObj.button_url) && (
        <Button
          className={classes.successButton}
          my={8}
          variant="contained"
          align="center"
          size="large"
          target="_blank"
          href={transObj.button_url}
        >
          {transObj.button_text}
        </Button>
      )}
    </Box>
  );
};

export default Success;
