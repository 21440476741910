import { ThemeProvider } from '@material-ui/core';
import { useEffect, useState } from 'react';
import getTheme from '../utils/theme';
import { lumoaUiHost } from '../config';
import Survey from './Survey';

const Preview = () => {
  const [config, setConfig] = useState();
  const [step, setStep] = useState('form');
  const [listenerAttached, setListenerAttached] = useState(false);

  const listenerFunction = (event) => {
    // IMPORTANT: check the origin of the data!
    // this does mean that you can not access test-ui from localhost. localhost must access localhost, test must access test etc...
    if (event.origin === lumoaUiHost) {
      if (event?.data?.step) {
        setStep(() => event.data.step);
      } else if (event?.data?.config) {
        setConfig(event.data?.config);
      }
    }
  };

  useEffect(() => {
    if (!listenerAttached) {
      setListenerAttached(true);
      window.addEventListener('message', listenerFunction);
      global.parent?.postMessage?.('load', lumoaUiHost);
    }

    return () => {
      window.removeEventListener('message', listenerFunction);
      return false;
    };
  }, []);

  return config ? (
    <ThemeProvider theme={getTheme(config)}>
      <Survey config={config} previewMode step={step} stepChanged={setStep} />
    </ThemeProvider>
  ) : null;
};

export default Preview;
