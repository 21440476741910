import { createContext, useMemo } from 'react';
import { useLanguage } from '../utils/hooks';

export const LanguageContext = createContext({});

const LanguageProvider = ({ children, language }) => {
  const {
    lang = language,
    setLang,
    localizedLanguages,
    loadLangsFromConfig,
  } = useLanguage();

  const providerValues = useMemo(
    () => ({
      lang,
      setLang,
      loadLangsFromConfig,
      localizedLanguages,
    }),
    [lang, setLang, loadLangsFromConfig, localizedLanguages],
  );

  return (
    <LanguageContext.Provider value={providerValues}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
