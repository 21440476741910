import { makeStyles } from '@material-ui/core';

const getScoreColor = ({ selected, level }, theme) => {
  if (selected) {
    return theme.palette.form;
  }
  const themeLevel = theme.palette[level];
  if (themeLevel) {
    return themeLevel;
  }
  return theme.palette.primary.main;
};

const getScoreBg = ({ selected, level }, theme) => {
  if (!selected) {
    return theme.palette.form;
  }
  const themeLevel = theme.palette[level];
  if (themeLevel) {
    return themeLevel;
  }
  return theme.palette.primary.main;
};

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    color: getScoreColor(props, theme),
    border: `1px solid ${theme.palette.score_border}`,
    backgroundColor: getScoreBg(props, theme),
    width: '38px',
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    fontWeight: 'bold',
    fontSize: '18px',
  }),
}));

const ScoreValue = ({ value, level, selected }) => {
  const classes = useStyles({ selected, level });
  return <span className={classes.root}>{value}</span>;
};

export default ScoreValue;
